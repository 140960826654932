import React from 'react';
import LatestArticleCell from './LatestArticleCell';
import './RecommendedArticles.css';
import blogData from '../../styles/Screens/BlogPageScreen/blogData';

const RecommendedArticles = () => {
    const articles = Object.values(blogData).slice(0, 3);

    return (
        <div className="recommended-articles-container">
            <div className="recommended-articles-grid">
                {articles.map((article, index) => (
                    <LatestArticleCell
                        key={article.id}
                        id={index+1}
                        imageSrc={article.leftImage}
                        label={article.tag}
                        title={article.mainTitle}
                        description={article.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default RecommendedArticles;