import React from 'react';
import '../../styles/components/basket/BasketCell.css'
import { stringAsObject } from '../../helpers/parser';

const BasketItemCell = ({ product, totalPrice, quantity, onAdd, onRemove }) => {
    const { image, name, description, new_price } = product;

    const handleIncrement = () => {
        onAdd(product.id, quantity + 1);
    };

    const handleDecrement = () => {
        onRemove(product.id, quantity - 1);

    };

    return (
        <div className="basket-item-cell">
            <div className="basket-item-image">
                <img src={image} alt={name} />
            </div>
            <div className='basket-item-info'>
                <div>
                    <h3 className="name text-xl semibold blue-text mb-2 mt-1">{name}</h3>
                    <p className='basket-item-description montserrat-m medium mb-2'>{stringAsObject(description).description}</p>
                    <p className="basket-item-price-in-description text-xl semibold blue-text">{new_price} zł / szt.</p>
                </div>
                <div className="basket-item-quantity">
                    <button onClick={handleDecrement} className="quantity-btn">-</button>
                    <div className='basket-quantitiy-width'>
                        <span className="montserrat-sm medium">{quantity}</span>
                    </div>
                    <button onClick={handleIncrement} className="quantity-btn quantity-btn-plus">+</button>
                </div>
            </div>
            <div className="basket-item-price">
                <p className="price-per-unit text-xl semibold blue-text">{new_price} zł / szt.</p>
            </div>
        </div>
    );
};

export default BasketItemCell;
