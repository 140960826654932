import React, { useEffect, useState } from'react';
// import styles from './landing.module.css'; // Assuming you have the Webflow styles converted
import './css/LandingPage.css'

const WebflowPage = () => {
  //   return (
  //     <div className='landingPageContainer'>
  //       <iframe src="landingIndex.html"></iframe>
  //     </div>
  //   );
  // };
  // let [htmlFileString, setHtmlFileString] = useState();

  // async function fetchHtml() {
  //   setHtmlFileString(await (await fetch(`landingIndex.html`)).text());
  // }
  useEffect(() => {
    window.open('./landing.html', "_self")
  }, []);

  return (
    <div className="landingPageContainer">
      {/* <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div> */}
    </div>
  );
}


export default WebflowPage;