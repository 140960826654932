import { sendEventToGTM } from "../../analytics/AnalyticsManager";
import { getRequest, postRequest } from "../HttpService";

export const getUserSubscriptions = async () => {
    try {
        const response = await getRequest("/api/subscription")
        return response
    } catch (error) {
        throw error
    }
}

export const cancelUserSubscriptino = async () => {
    try {
        const response = await postRequest("api/subscription/cancel")
        sendEventToGTM({
            eventName: 'Subscription',
            action: 'cancel',
            label: 'cancel',
            value: '0',
        });
        return response 
    } catch (error) {
        throw error
    }
}