import React from "react";
import '../../styles/components/buttons/SecondaryButton.css'

const SecondaryButton = ({ text, onClick, type = 'button', disabled = false}) => {
    return(
        <button
            className="secondary-button"
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            <p className="mt-1 mb-1 black-text text-sm">{text}</p>
        </button>
    )
}

export default SecondaryButton;