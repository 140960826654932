import React, { useEffect, useState } from "react";
import '../../styles/components/blogHeaderView/BlogHeaderView.css'


const BlogHeaderMobile = ({ mainTitle, leftImage, cellImage, cellTitle, cellSubtitle, tag = "" }) => {
    return (
        <div className="blog-header-mobile-container">
            <div class="blog-rounded-tag text-s">Super długi tag</div>
            <div className="mobile-blog-header-padding">
                <h1 className="display-xl medium blue-text">{mainTitle}</h1>
                <div className="mobile-blog-header-small-box">
                    <img src={cellImage} alt="Small visual" className="mobile-blog-header-small-image" />
                    <div className="mobile-blog-header-text-content">
                        <h2 className="text-md medium blue-text">{cellTitle}</h2>
                        <p className="text-xs medium gray-text">{cellSubtitle}</p>
                    </div>
                </div>
            </div>
            <img src={leftImage} alt="Main visual" className="blog-header-image" />
        </div>
    );
};

const BlogHeaderView = ({ mainTitle, leftImage, cellImage, cellTitle, cellSubtitle }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (isMobile) {
        return (
            <BlogHeaderMobile
                mainTitle={mainTitle}
                leftImage={leftImage}
                cellImage={cellImage}
                cellTitle={cellTitle}
                cellSubtitle={cellSubtitle}
            />
        );
    }

    return (
        <div className="blog-bg-container">
            <div className="blog-header-container">
                <div className="blog-header-left">
                    <img src={leftImage} alt="Main visual" className="blog-header-image" />
                </div>
                <div className="blog-header-right">
                    <div className="blog-header-padding">
                        <div class="blog-rounded-tag text-s">"Super długi tag"</div>
                        <h1 className="display-xl medium blue-text">{mainTitle}</h1>
                        <div className="blog-header-small-box">
                            <img src={cellImage} alt="Small visual" className="blog-header-small-image" />
                            <div className="blog-header-text-content">
                                <h2 className="text-md medium blue-text">{cellTitle}</h2>
                                <p className="text-xs medium gray-text">{cellSubtitle}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogHeaderView