import React from "react";
import '../styles/components/Separator.css';

const Separator = ({ width = '100%', color = '#ddd', margin = '16px 0', ...props }) => {
    return (
        <div
            className="separator"
            style={{
                width: width,
                backgroundColor: color,
                margin: margin,
            }}
            {...props}
        ></div>
    );
};

export default Separator;