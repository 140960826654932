import React from "react";
import '../../styles/components/promo/AppPromoSection.css';

const AppPromoSection = () => {
    return (
        <div className="promo-section">
            <div className="promo-text">
                <div className="promo-text-container">
                    <p className="highlight-dark-text montserrat-md">Aplikacja mobilna</p>
                    <h1 className="mt-3">
                        <span className="highlight-text italic-text display-xl">Shape Factor </span>
                        <span className="blue-text display-xl"> w Twoim Telefonie: Twoje Przenośne Centrum Fitness!</span>
                    </h1>
                    <p className="mt-4 montserrat-md">
                        Odkryj pełnię potencjału naszej mobilnej aplikacji, która przynosi treningi "Shape Factor" na wyciągnięcie ręki.
                        Zainstaluj naszą aplikację i zacznij formować swoją sylwetkę z dowolnego miejsca i o każdej porze dnia.
                    </p>
                </div>
                <div className="promo-app-badges mt-5">
                    <img src="/socials/app-store-small.svg" alt="Download on the App Store" className="" />
                    <img src="/socials/google-play-small.svg" alt="Get it on Google Play" className="" />
                </div>
            </div>

            <div className="promo-image">
                <img src="/app-promo-large.svg" alt="Promo" />
            </div>
        </div>
    );
};

export default AppPromoSection;