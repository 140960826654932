import { signOut } from 'firebase/auth';
import { auth } from './firebase.config';

export const logout = async () => {
      try {
        await signOut(auth);
        localStorage.removeItem('userPreferences');
      } catch (error) {
        console.error('Error signing out:', error);
        throw error;
    };
};