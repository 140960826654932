import React from 'react';
import '../../../styles/components/programs/ProgramsCell.css'
import { ArrowRight } from 'react-feather';


const truncateDescription = (description, maxLength = 80) => {
    return description.length > maxLength
        ? `${description.slice(0, maxLength)}...`
        : description;
};

const ProgramsCell = ({ title, description, image, background, onClick }) => {
    return (
        <div className="programs-cell" onClick={onClick}>
            <div className='programs-top-container'>
                <img
                    src={background}
                    alt={background}
                    className="programs-background-image"
                />
                <div className="programs-image-container">
                    <img
                        src={image}
                        alt={image}
                        className="programs-image"
                    />
                </div>
            </div>
            <div className="programs-content">
                <div className="programs-text-container">
                    <h3 className='mb-3 display-xs medium-italic'>
                        {title}
                    </h3>
                    <p className='montserrat- medium'>
                        {truncateDescription(description)}
                    </p>
                </div>
                <div className="arrow-icon">
                    <ArrowRight size={24} />
                </div>
            </div>
        </div>
    );
};

export default ProgramsCell;