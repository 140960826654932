import React, { useState, useEffect } from 'react';
import './ArticleGrid.css';
import blogData from '../../styles/Screens/BlogPageScreen/blogData';
import LatestArticleCell from '../LatestArticleCell/LatestArticleCell';
import { ArrowRight } from 'react-feather';


const FeaturedArticleGrid = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1068);
    const articles = Object.values(blogData).slice(0, 4);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isMobile) {
        return (
            <div className="featured-article-horizontal-list">
                {articles.map((article, index) => (
                    <div className="featured-article-cell-mobile" key={index} style={{ cursor: 'pointer' }}>
                        <div className="featured-article-image-container-mobile">
                            <img src={article.leftImage} alt="Article visual" className="featured-article-image" />
                            <span className="featured-article-label-tag text-s medium">{article.tag}</span>
                        </div>
                        <div className="featured-article-content">
                            <div className="featured-article-title-container">
                                <h3 className="featured-article-title display-xs medium">{article.mainTitle}</h3>
                                {/* <span className="featured-article-arrow">→</span> */}
                                <ArrowRight size={24} />
                            </div>
                            <p className="featured-article-description montserrat-sm medium">{article.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className='featured-article-background'>
            <div class="featured-article">
                <div class="featured-article__left">
                    <div className="featured-article-cell" style={{ cursor: 'pointer' }}>
                        <div className="featured-article-image-container">
                            <img src={articles[0].leftImage} alt="Article visual" className="featured-article-image" />
                            <span className="featured-article-label-tag text-s medium">{articles[0].tag}</span>
                        </div>
                        <div className="featured-article-content">
                            <div className="featured-article-title-container">
                                <h3 className="featured-article-title display-md medium">{articles[0].mainTitle}</h3>
                                <span className="featured-article-arrow">
                                    <ArrowRight size={24} />
                                </span>
                            </div>
                            <p className="featured-article-description montserrat-sm medium">{articles[0].description}</p>
                        </div>
                    </div>
                </div>
                <div class="featured-article__right">
                    <div className='featured-article-list'>
                        {articles.slice(1, 4).map((article, index) => (
                            <React.Fragment key={index}>
                            <div className="featured-article-subarticle" key={index}>
                                <div className="featured-article-subarticle__image-container">
                                    <img src={article.leftImage} alt="Opis obrazka" className="featured-article-subarticle__image" />
                                    <div className="featured-article-subarticle__label text-s medium">{article.tag}</div>
                                </div>
                                <div className="featured-article-subarticle__content">
                                    <h2 className="featured-article-subarticle__title text-xl medium">{article.mainTitle}</h2>
                                    {/* <a href="#" className="featured-article-subarticle__link">→</a> */}
                                    <ArrowRight size={24} color='var(--color-primary)'/>
                                </div>
                            </div>
                            <div className="divider"></div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturedArticleGrid;