import React, { useState, useEffect } from 'react'
import '../../styles/Screens/PaywallScreen/PaywallScreen.css';
import TopNavigationBar from '../../components/NavigationBar/TopNavigationBar';
import AppPromoSection from '../../components/promo/AppPromoSection';
import BottomBar from '../../components/BottomBar/BottomBar';
import MetamorphosesContent from '../../components/Metamorphoses/MetamorphosesContent';
import OfferContent from '../../components/Offer/OfferContent';
import FreeGiftContent from '../../components/FreeGifts/FreeGiftsContent';
import ExampleWorkoutContent from '../../components/ExampleWorkoutProgram/ExampleWorkoutContent';
import ReviewList from '../../components/Reviews/PaywallReviewContent';

const PaywallScreen = () => (
    <div>
        <TopNavigationBar />
        <div className='plan-container'>
            <p className='mb-2 highlight-text montserrat-md'>Wybierz plan</p>
            <h2>
                <snap className='display-xl'> Rozpocznij przygodę z Shape Factor i <br /> odbierz  </snap>
                <span className="highlight-text italic-text display-xl">darmowy zestaw gum treningowych </span>
            </h2>
        </div>

        <div>
            <OfferContent />
        </div>

        <div>
            <MetamorphosesContent />
        </div>

        <div>
            <FreeGiftContent />
        </div>
        <div>
            <ExampleWorkoutContent/>
        </div>
        {/* <div>
            <ReviewList />
        </div> */}
        <div>
            <AppPromoSection />
        </div>
        <BottomBar />
    </div>
);

export default PaywallScreen