import React, { useState, useEffect } from "react";
import ProductCell from "./ProductCell";
import '../../styles/components/productCell/ProductList.css'
import { fetchProducts, getProductDetails } from "../../services/shop/productService";
import { useNavigate } from "react-router-dom";
import { addProduct } from '../../services/basket/basketService'
import { useCart } from "../../providers/CartContext";
import { useAuth } from "../../services/authorization/authContext";
import AddToCartModal from "../addToCardModal/AddToCardModal";

const ProductList = () => {
    const { user, ldng } = useAuth();
    const { updateCartCount, itemsInBasket } = useCart();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [addedProduct, setAddedProduct] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getProducts = async () => {
            try {
                const data = await fetchProducts();
                console.log(data || []);
                setProducts(data || []);
            } catch (error) {
                console.log(error);
                setProducts([]);
            } finally {
                setLoading(false);
            }
        }

        getProducts();
    }, []);

    const handleAddClicked = async (e, product) => {
        e.preventDefault();

        try {
            const id = product.id;
            const data = await addProduct(id, user);
            updateCartCount(itemsInBasket + 1);

            setAddedProduct(product);
            setModalVisible(true);

            setTimeout(() => {
                setModalVisible(false);
            }, 3000);
        } catch (error) {
            console.log(error);
        }
    };

    const handleOnCellClicked = async (e, product) => {
        e.preventDefault();

        navigate(`/productDetails?id=${product.id}`)
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="product-container">
            {products && products.map((product) => (
                <ProductCell
                    key={product.id}
                    product={product}
                    onClick={(e) => handleAddClicked(e, product)}
                    onCellClick={(e) => handleOnCellClicked(e, product) }
                />
            ))}
            {modalVisible && addedProduct && (
                <AddToCartModal
                    product={addedProduct}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    );
};

export default ProductList;
