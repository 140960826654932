import '../../../styles/components/training/TrainingList.css'
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { fetchWorkoutsForProgram } from "../../../services/training/WorkoutsService";
import TrainingCell from "./TrainingCell";
import { sendEventToGTM } from '../../../analytics/AnalyticsManager';

const TrainingList = ({ programId }) => {
    const [trainings, setTrainings] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const getWorkouts = async () => {
            const fetchedData = await fetchWorkoutsForProgram(programId);

            setTrainings(fetchedData);
            setLoading(false);
        }

        getWorkouts();
    }, [programId]);

    const handleCellClick = (program) => {
        sendEventToGTM({
            eventName: 'Exercises',
            action: 'Open',
            label: `"${String(program.name)}"`,
            value: '1',
        });
        sendEventToGTM({
            eventName: 'navigation',
            action: 'Go to',
            label: '/workout',
            value: '1',
        });
        navigate(`/workout?idx=${program.id}`);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='training-container'>
            <div className='training-header'>
                <h2 className='display-sm medium'>Moduły treningowe - {trainings.length}</h2>
            </div>
            <div className='training-list'>
                {trainings
                    .sort((a, b) => a.order > b.order ? 1 : -1)
                    .map((training) => (
                        <TrainingCell
                            key={training.id}
                            title={training.name}
                            time={training.duration}
                            level={training.level}
                            equipment={training.equipment.join(', ')}
                            imageUrl={training.image}
                            onClick={() => handleCellClick(training)}
                        />
                    ))}
            </div>
        </div>
    );
}

export default TrainingList