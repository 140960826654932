import React from "react";
import '../../styles/Screens/ShopScreen/ShopScreen.css'
import Footer from "../../components/Footer/Footer.jsx";
import BottomBar from "../../components/BottomBar/BottomBar.jsx";
import AppPromoSection from "../../components/promo/AppPromoSection.jsx";
import TopNavigationBar from "../../components/NavigationBar/TopNavigationBar.jsx";
import ProductList from "../../components/productCell/ProductList.jsx";

const ShopScreen = () => {
    return (
        <div>
            <div>
                <TopNavigationBar />
            </div>
            <div className="shop-header-container">
                <p className="text-md semibold primary-text">Sklep</p>
                <h2 className="display-xl medium">Sprawdź nasze <snap className='display-xl medium-italic primary-text'>produkty</snap></h2>
                <p className="montserrat-md medium blue-text">W naszym sklepie znajdziesz starannie wyselekcjonowane akcesoria do ćwiczeń, które wspierają Twoje wysiłki w ramach programu Shape Factor. </p>
            </div>
            <div>
                <ProductList />
            </div>
            <div>
                <AppPromoSection />
            </div>
            <div>
                <Footer />
            </div>
            <div>
                <BottomBar />
            </div>
        </div>
    );
};

export default ShopScreen