import { collection, getDocs, getDoc, doc } from 'firebase/firestore'
import { db } from '../authorization/firebase.config.js'

export const fetchPrograms = async () => {
  try {
    const programsCollection = collection(db, "programs");
    const programsSnapshot = await getDocs(programsCollection);
    const programsList = programsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return programsList;
  } catch (error) {
    console.error("Error fetching programs:", error);
    return [];
  }
};

export const fetchProgram = async (programId) => {
  try {
    const programDocRef = doc(db, "programs", programId);
    const programDoc = await getDoc(programDocRef);
    if (programDoc.exists()) {
      return {
        id: programDoc.id,
        ...programDoc.data()
      };
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching program:", error);
    return null;
  }
};

// I mean idk why I mark it as a program its more like a fetchWorkoutsById
export const fetchProgramById = async (programRef) => {
  try {
    const programDoc = await getDoc(programRef);

    if (programDoc.exists()) {
      let programData = programDoc.data();

      if (typeof programData.trainingSchedule === 'string') {
        try {
          programData.trainingSchedule = JSON.parse(programData.trainingSchedule);

        } catch (parseError) {
          console.error("Error parsing trainingSchedule:", parseError);
        }
      }

      return programData; 
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching program:", error);
    return null;
  }
};