import { getRequest, postRequest } from "../HttpService";

export const getGuestBasketUID = () => {
    return localStorage.getItem('guestBasketUID');
};

const setGuestBasketUID = (uid) => {
    localStorage.setItem('guestBasketUID', uid);
};

const fetchGuestBasket = async (uid) => {
    const response = await getRequest(`/api/guest/basket/${uid}`);
    return response.data;
};

const createGuestBasket = async () => {
    const response = await postRequest('/api/guest/basket');
    const uid = response.uid;
    setGuestBasketUID(uid);
    return uid;
};

export const getBasket = async (user) => { 
    try {
        if (user) {
            const response = await getRequest('/api/basket');
            return response.data;
        } else {
            let uid = getGuestBasketUID();

            if (uid) {
                return await fetchGuestBasket(uid);
            } else {
                let createdUID = await createGuestBasket();
                return await fetchGuestBasket(createdUID);
            }
        }
    } catch (error) {
        throw error;
    }
};

export const addProduct = async (id, user) => {
    try {
        const body = { product_id: id };

        if (user) {
            const response = await postRequest('/api/basket/add', body);
            return response;
        } else {
            let uid = getGuestBasketUID();

            if (!uid) {
                uid = await createGuestBasket();
            }

            const guestBody = { ...body, uid };
            const response = await postRequest(`/api/guest/basket/add`, guestBody);
            return response;
        }
    } catch (error) {
        throw error;
    }
};

export const removeProduct = async (id, user) => {
    try {
        const body = { product_id: id };

        if (user) {
            const response = await postRequest('/api/basket/remove', body);
            return response;
        } else {
            let uid = getGuestBasketUID();

            if (!uid) {
                uid = await createGuestBasket();
            }

            const guestBody = { ...body, uid };
            const response = await postRequest(`/api/guest/basket/remove`, guestBody);
            return response;
        }
    } catch (error) {
        throw error;
    }
};