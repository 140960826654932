import React, { useState, useEffect } from 'react';
import '../../styles/components/freeGifts/FreeGiftsCell.css';
import PrimaryButton from '../buttons/PrimaryButton';

const FreeGiftsCell = ({ planText, highlited, title, image, description, onClick }) => {
    return (
        <div className="free-gifts-cell-container">
            <img src={image} alt="Image" className='image-container'></img>
            <p className="plan-text text-xs">
                Przy zakupie <span className="highlight-plan text-xs">{planText}</span>
            </p>
            <h2 className="gift-title">
                <span className="highlight-gift display-sm">{highlited} </span><snap className = 'display-sm'>{title}</snap>
            </h2>
            <p className="gift-description montserrat-sm">{description}</p>
            <PrimaryButton text="Odbierz prezent" onClick={onClick} />
        </div>
    );
};

export default FreeGiftsCell;