import React from "react";
import '../../styles/components/metamorphoses/MetamorphosesContent.css'
import MetamorphosesCell from "./MetamorphosesCell";
import useDragScroll from "../../helpers/useDragScroll";

const MetamorphosesContent = () => {
    const { scrollRef, handleMouseDown, handleMouseLeave, handleMouseUp, handleMouseMove } = useDragScroll();

    return (
        <div className="base-container">
            <div className="text-container">
                <p>
                    <snap className='montserrat-md semibold'>Metamorfozy</snap>
                </p>
                <h1>
                    <snap className='display-xl'>Najbardziej skuteczna </snap><br/>
                    <snap className="italic-text highlight-text display-xl">aplikacja treningowa</snap>
                    <snap className='display-xl'> tego roku</snap>
                </h1>
            </div>
            <div className="scrollable-container">
                <div
                    className="scrollable-container"
                    ref={scrollRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                >
                    <MetamorphosesCell
                        name="Patrycja"
                        description="Cardiomasakra dosłownie odmieniła moje życie"
                        image="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/images%2Fmetamorphose-1.jpg?alt=media&token=851bb808-a12f-4f30-a6bf-cf1ba0e70ff3"
                    />
                    <MetamorphosesCell
                        name="Amelia"
                        description="Zakochałam się w formule Cardiomasakry"
                        image="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/images%2Fmetamorphose-2.jpg?alt=media&token=e1eb0c7f-4385-42be-83b6-24d20a02e83b"
                    />
                    <MetamorphosesCell
                        name="Ania"
                        description="Każdy trening jest zupełnie inny od poprzedniego"
                        image="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/images%2Fmetamorphose-3.jpg?alt=media&token=7a70ff38-7c63-4587-924b-1658298708a3"
                    />
                    <MetamorphosesCell
                        name="Oliwia"
                        description="Można osiągnąć fajną formę ćwicząc w domu"
                        image="https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/images%2Fmetamorphose-4.jpg?alt=media&token=d85904ab-1c00-4587-9aa6-a6ac2d02bcd1"
                    />
                </div>
            </div>
        </div>
    );
};

export default MetamorphosesContent