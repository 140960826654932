import React from 'react'
import { useParams } from 'react-router-dom'
import TopNavigationBar from '../../components/NavigationBar/TopNavigationBar'
import AppPromoSection from '../../components/promo/AppPromoSection'
import BottomBar from '../../components/BottomBar/BottomBar'
import BlogHeaderView from '../../components/blogHeaderComponent/BlogHeaderView'
import '../../styles/Screens/BlogPageScreen/BlogPageScreen.css'
import blogData from '../../styles/Screens/BlogPageScreen/blogData'
import RecommendedArticles from '../../components/LatestArticleCell/RecommendedArticles'

const BlogPageScreen = () => {
    const { id } = useParams();

    const selectedBlog = blogData[id] || {
        mainTitle: "Domyślny tytuł",
        leftImage: '/defaultImage.svg',
        cellTitle: 'Autor nieznany',
        cellSubtitle: 'Data nieznana',
        content: <div>Treść domyślna, ponieważ nie znaleziono wpisu dla tego ID.</div>,
    };

    return (
        <div>
            <TopNavigationBar />
            <div>
                <BlogHeaderView
                    mainTitle={selectedBlog.mainTitle}
                    leftImage={selectedBlog.leftImage}
                    cellImage={"/images/default_blog_avatar.svg"}
                    cellTitle={selectedBlog.cellTitle}
                    cellSubtitle={selectedBlog.cellSubtitle}
                />
            </div>
            <div className='blog-page-content-view-spacer' />
            <div className='blog-page-content-view'>
                {selectedBlog.content}
            </div>
            <div>
            <p className='display-md medium blue-text mt-4 mb-4' style={{margin: 32}}> Podobne artykuły </p>
            </div>
            <div>
                <RecommendedArticles />
            </div>
            <div>
                <AppPromoSection />
            </div>
            <BottomBar />
        </div>
    )
}

export default BlogPageScreen