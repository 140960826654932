import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../services/authorization/authContext';
import { checkUserSubscription } from '../../services/user/checkUserSubscription';

export const PrivateRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export const SecuredRoute = ({ children }) => {
  const [hasSubscription, setHasSubscription] = useState(null);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const subscriptionStatus = await checkUserSubscription();
        setHasSubscription(subscriptionStatus);
      } catch (error) {
        console.error('Błąd podczas sprawdzania subskrypcji:', error);
        setHasSubscription(false);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  if (hasSubscription === false) {
    return <Navigate to="/paywall" />;
  }

  if (hasSubscription === null) {
    return <div>Ładowanie...</div>;
  }

  return children;
};