import React, { useState } from 'react';
import '../../styles/components/textFields/TextField.css';
import '../../styles/global.css';

const TextField = ({ label, placeholder, value, onChange = () => { }, required = false }) => {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState('');

  const validate = (value) => {
    if (required && !value.trim()) {
      setError('To pole jest wymagane');
    } else {
      setError('');
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    onChange(newValue);
    if (touched) {
      validate(newValue);
    }
  };

  const handleBlur = () => {
    setTouched(true);
    validate(value);
  };

  return (
    <div className="text-field">
      <label className="text-field-title text-sm medium">
          <snap className='text-sm medium'>{label}</snap>
      </label>
      <input
        className={`text-sm text-field-input ${error ? 'border-error' : ''}`}
        placeholder={placeholder}
        value={value}
        onChange={ (e) => handleChange(e) }
        onBlur={handleBlur}
      />
      {error && <span className="mb-3 error-message text-sm medium">{error}</span>}
    </div>
  );
};

export default TextField;