import TagManager from "react-gtm-module";

export const sendEventToGTM = ({
    eventName,
    action,
    label,
    value,
    currency = null,
  }) => {
    try {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          action: action,
          label: label,
          value: value,
          currency: currency,
        },
      });
      // console.log(`Wysłano zdarzenie do GTM: ${eventName}, ${action}, ${label}, ${value}`);
  
      if (window.fbq) {
        // window.fbq('track', eventName, {
        //   action: action,
        //   label: label,
        //   value: value,
        //   currency: currency,
        // });
        // console.log(`Wysłano zdarzenie do Facebooka: ${eventName}, ${action}, ${label}, ${value}`);
      } else {
      }
    } catch (error) {
      console.error(`Wystąpił błąd podczas wysyłania zdarzenia: ${error}`);
    }
  };