import React, { useState, useEffect } from 'react'
import '../../styles/Screens/DashboardScreen/DashboardScreen.css';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primeicons/primeicons.css';

import TopNavigationBar from '../../components/NavigationBar/TopNavigationBar';
import BottomBar from '../../components/BottomBar/BottomBar';
import AppPromoSection from '../../components/promo/AppPromoSection';
import Footer from '../../components/Footer/Footer';
import ProgramsList from '../../components/Traning/ProgramsContent/ProgramsContent';
import { TabMenu } from 'primereact/tabmenu'

const DashboardScreen = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const items = [
        { label: 'Dom', icon: 'pi pi-fw pi-home', category: 'HOME' },
        {
            label: 'Siłownia',
            icon: <img src="./icons/barbell.svg" alt="Barbell Icon" className={`custom-tabmenu-icon ${activeIndex === 1 ? 'active' : ''}`} />,
            category: 'GYM'        },
    ];

    return (
        <div>
            <TopNavigationBar />
            <div className='tab-menu-container' >
                <h2 className='display-lg medium mb-3'>Programy</h2>
                <TabMenu
                    model={items}
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                />
            </div>
            <div className='mt-5 mb-5'>
                <ProgramsList selectedCategory={items[activeIndex].category} />
            </div>
            <div>
                <AppPromoSection />
            </div>
            <div>
                <Footer />
            </div>
            <BottomBar />
        </div>
    );
};

export default DashboardScreen