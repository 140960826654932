import React, { useState } from 'react'
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import '../../styles/components/textFields/SecuredTextField.css';

const SecuredTextField = ({ label, placeholder, validationError = false, value, onChange = () => {}, required = true }) => {
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    
    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text');
        } else {
            setIcon(eyeOff);
            setType('password');
        }
    };

    return (
        <div className="secured-textfield-container">
            {label && <label className="text-sm medium">{label}</label>}
            <div className="input-wrapper">
                <input
                    type={type}
                    name="password"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => onChange(e.target.value) }
                    required={required}
                    autoComplete="current-password"
                    className={`text-sm secured-input ${validationError ? 'border-error' : ''}`}
                />
                <span className="toggle-password-visibility" onClick={handleToggle}>
                    <Icon icon={icon} size={18} />
                </span>
            </div>
            {/* {validationError && <p className="error-message">Invalid password</p>} */}
        </div>
    );
};

export default SecuredTextField;