import { getRequest } from "../HttpService";

export const fetchProducts = async () => {
    try {
        const response = await getRequest("/api/product")
        return response.data.filter(item => item.product_type === "one_purchase")
    } catch (error) {
        throw error
    }
}

export const getProductDetails = async (id) => {
    try {
        const response = await getRequest(`/api/product?ids=${id}`)
        return response.data//.filter(item => item.product_type === "one_purchase")
    } catch (error) {
        throw error
    }
}