import React, { useState, useEffect } from 'react'
import { confirmPasswordResetAction } from '../../services/authorization/passwordReset';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import '../../styles/Screens/NewPasswordScreen/NewPasswordScreen.css';
import { sendEventToGTM } from '../../analytics/AnalyticsManager';
import SecuredTextField from '../../components/textFields/PasswordTextField';

const NewPasswordScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [oobCode, setOobCode] = useState('');
    const [error, setError] = useState('');
    const [isCodeValid, setIsCodeValid] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('oobCode');
    
        if (code) {
          setOobCode(code);
          setIsCodeValid(true);
        } else {
          setError('Nieprawidłowy link resetowania hasła.');
        }
    }, [location.search]);

    const handlePasswordReset = async (e) => {
        e.preventDefault();
    
        if (password.length < 8) {
          setError('Hasło musi mieć co najmniej 8 znaków.');
          return;
        }
    
        try {
          await confirmPasswordResetAction(oobCode, password);
          navigate('/signin');
        } catch (err) {
          console.error(err);
          setError('Nie udało się zresetować hasła. Spróbuj ponownie.');
        }
      };

    return (
        <div className="new-password-container">
            <div className="new-password-left">
                <div className="logo-container">
                    <img src="/logo-default.svg" alt="Shape Factor Logo" className="logo" />
                </div>
                <div style={{ flexGrow: 0.7 }}></div>
                <div className="form-container">
                    <div className='form-cntr'>
                        <div className='new-password-form mb-5'>
                            <h2 className='mb-2 display-lg'>Ustaw nowe hasło</h2>
                        </div>

                        <form className="new-password-form">
                            <div className='mt-4'>
                                <SecuredTextField
                                    label="Hasło"
                                    placeholder="Wpisz hasło"
                                    onChange={setPassword}
                                    required={true}
                                />
                                <p className='montserrat-sm mb-3'>Hasło musi składać się przynajmniej z 8 znaków</p>
                                <div className='mt-2'>
                                    <PrimaryButton text="Zresetuj moje hasło" onClick={handlePasswordReset}/>

                                    {error && <p className="error-message">{error}</p>}
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div style={{ flexGrow: 1 }}></div>
                <div className='mt-5'>
                    <p className="new-password-footer text-sm">
                        Masz pytanie?  <a className='text-sm semibold' href="/login">pomoc@shapefactor.pl</a>
                    </p>
                </div>
                <div style={{ flexGrow: 1 }}></div>
            </div>

            <div className="new-password-right">
                <img src="/password-reset/password_reset_default_img.svg" alt="Promo" className="promo-image" />
                <div className="app-promo-section">
                    <h2 className='display-md'>
                        <span className="highlight-text italic-text">Każdy poziom.</span> Certyfikowany Trener osobisty w Twoim telefonie.
                    </h2>
                    <p className="text-md medium-italic mt-3 mb-5">Pobierz aplikację Shape Factor na swój telefon</p>
                    <div className="app-store-buttons">
                        <img src="/login/AppStore.svg" alt="Download on the App Store" className="app-badge" />
                        <img src="/login/GooglePlay.svg" alt="Get it on Google Play" className="app-badge" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewPasswordScreen