import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthActionHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');

    if (!mode || !oobCode) {
      navigate('/');
      return;
    }

    switch (mode) {
      case 'resetPassword':
        navigate(`/createNewPassword?oobCode=${oobCode}`);
        break;
      case 'verifyEmail':

        break;
      default:

        navigate('/');
        break;
    }
  }, [location.search, navigate]);

  return (
    <div>
      Trwa przetwarzanie Twojej prośby...
    </div>
  );
};

export default AuthActionHandler;