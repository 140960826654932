import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/bottomBar/BottomBar.css';

const BottomBar = () => {
    return (
        <nav className="bottom">
            <div className="bottom-logo">
                <img src="/logo-default.svg" alt="Shape Factor Logo" className="logo" />
            </div>
            <div>
                <p className='text-xs'>© SHAPEFACTOR 2024. Wszystkie prawa zastrzeżone</p>
            </div>
            <div className="bottom-links">
                <p className='text-xs'><Link to="/terms">Cookies</Link></p>
                <p className='text-xs'><Link to="/terms">Polityka prywatności</Link></p>
                <p className='text-xs'><Link to="/terms">Regulamin</Link></p>
            </div>
        </nav>
    );
};


export default BottomBar