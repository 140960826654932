import React from 'react';
import '../../styles/components/stacks/HStack.css'

const HStack = ({ children, gap = '16px', justifyContent = 'flex-start', alignItems = 'center', className = '', ...props }) => {
    return (
      <div
        className={`hstack ${className}`}
        style={{
          gap: gap,
          justifyContent: justifyContent,
          alignItems: alignItems,
        }}
        {...props}
      >
        {children}
      </div>
    );
  };
  
  export default HStack;