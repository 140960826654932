import { OAuthProvider, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from './firebase.config';
import { getRequest, postRequest } from '../HttpService';

export const AuthProviders = {
    APPLE: 'APPLE',
    GOOGLE: 'GOOGLE',
    STANDARD: 'STANDARD'
}

export const signUp = async (email, password, type) => {
    let usr, body
    switch (type) {
        case AuthProviders.STANDARD:
            usr = await signUpWithPassword(email, password);
            body = {
                email: email,
                ...body
            }
            break;
        case AuthProviders.GOOGLE:
            usr = await signUpWithGoogle();
            body = {
                email: usr.email,
                ...body
            }
            break;
        case AuthProviders.APPLE:
            usr = await signUpWithApple();
            body = {
                email: usr.email,
                ...body
            }
            break;
    }

    const date = new Date();

    body = {
        ...body,
        created_time: date.toISOString(),
        last_activity: date.toISOString(),
        phone_number: 0,
        full_name: "Sarna",
        address: {
            city: "",
            street: "",
            zipCode: "",
        }
    }
    const registerResponse = await postRequest('/api/user/register', body)
    return registerResponse
}

const signUpWithPassword = async (email, password) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user
        console.log('User signed up:', user);
        return user;
    } catch (error) {
        console.error('Error singin up', error.message);
        return error;
    }
}

const signUpWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        console.log('Zarejestrowano jako:', user.displayName);
        return user;
    } catch (error) {
        console.error('Błąd podczas rejestracji z Google:', error.message);
        throw error;
    }
};

const signUpWithApple = async () => {
    const provider = new OAuthProvider('apple.com');

    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        console.log('Zarejestrowano jako:', user.displayName);
        return user;
    } catch (error) {
        console.error('Błąd podczas rejestracji z Apple:', error.message);
        throw error;
    }
};