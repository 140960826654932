import React from 'react';
import '../../styles/components/checkboxes/Checkbox.css';

const Checkbox = ({ label, checked, onChange = () => { }, required = false }) => {
  const handleCheckboxChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div className="checkbox-wrapper">
      <input
        type="checkbox"
        className="checkbox-input"
        checked={checked}
        onChange={handleCheckboxChange}
        id={`checkbox-${label}`}
      />
      <label
        htmlFor={`checkbox-${label}`}
        className={`checkbox-label ${required ? 'required' : ''}`}
      >
        <snap className='text-s'>{label}</snap>
      </label>
    </div>
  );
};

export default Checkbox;