import { collection, getDocs } from "firebase/firestore";
import { db } from "../authorization/firebase.config";
import { getRequest, postRequest } from "../HttpService";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

export const fetchPlanFeatures = async () => {
  try {
    const response = await getRequest("/api/product")
    console.log(response);
    return response.data.filter(item => item.product_type != "one_purchase")
  } catch (error) {
    console.error("Error fetching offer: ", error);
    return [];
  }
};

export const createNewSubscriptionsIntent = async (id) => {
  try {
    const body = { product_id: id }

    const response = await postRequest('api/subscription/create', body)
    sendEventToGTM({
      eventName: 'subscription_init',
      action: 'subscription_init',
      label: `${String(id)}`,
      value: '1',
  });
    window.open(response.payment_url, "_self")
    return response
  } catch (error) {
    return error
  }
}

export const updateSubscription = async (product) => {
  try {
    const response = await postRequest('api/subscription/update', product)
    return response;
  } catch (error) {
    return error
  }
}