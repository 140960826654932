import React from 'react';
import '../../styles/components/metamorphoses/MetamorphosesCell.css'; 

const metamorphosesCell = ({ name, description = "", image = "" }) => {
    return (
        <div className="metamorphoses-card">
            <img src={image} alt={`${name} Before`} className="metamorphoses-image" />
            <div className="metamorphoses-text">
                <h1 className="metamorphoses-name italic-text text-lg">{name}</h1>
                <h2 className="metamorphoses-description display-sm">{description}</h2>
            </div>
        </div>
    );
};

export default metamorphosesCell;