import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/components/TopNavigationBar.css';
import { MdOutlineMenu, MdClose } from 'react-icons/md'
import { useState } from "react";
import { logout } from '../../services/authorization/logOut'
import Separator from '../Separator';
import { ShoppingBag } from 'react-feather';
import { useCart } from '../../providers/CartContext';
import { useAuth } from '../../services/authorization/authContext';
import { sendEventToGTM } from '../../analytics/AnalyticsManager';

const MobileTopNavigationBar = () => {
    const { user, loading } = useAuth();
    const navigate = useNavigate();
    const { itemsInBasket } = useCart();
    const [click, setClick] = useState(false);
    const closeMenu = () => setClick(false);

    const Hamburger = <MdOutlineMenu className="HamburgerMenu"
        size="30px"
        color="black"
        onClick={() => setClick(!click)} />

    const Close = <MdClose className="HamburgerMenu"
        size="30px" color="black"
        onClick={() => setClick(!click)} />

    const handleLogout = async (e) => {
        e.preventDefault();

        try {
            await logout();
            sendEventToGTM({
                eventName: 'navigation',
                action: 'Go to',
                label: '/',
                value: '1',
            });
            navigate('/')
        } catch {
            console.log('Error')
        }
    };

    const NavLinks = ({ isClicked, closeMenu }) => {
        return <nav className="NavLinks">
            <ul>
                {user === null ? (
                    <li onClick={closeMenu}>
                        <p><Link to="/shop">Sklep</Link></p>
                    </li>
                ) : (
                    <>
                        <li onClick={() => isClicked && closeMenu()}>
                            <p><Link to="/dashboard">Programy</Link></p>
                        </li>
                        <li>
                            <p><Link to="/shop">Sklep</Link></p>
                        </li>
                        {/* <li>
                            <p><Link to="/blog">Blog</Link></p>
                        </li> */}

                        <Separator />
                        <Link to="/profile?=account"><li onClick={closeMenu}><img src={'./icons/dropdown-user.svg'} alt="Edycja danych" /> Edycja danych</li></Link>
                        <Link to="/profile?=orders"><li onClick={closeMenu}><img src={'./icons/dropdown-order.svg'} alt="Zamówienia" /> Zamówienia</li></Link>
                        <Link to="/profile?=subscriptions"><li onClick={closeMenu}><img src={'./icons/dropdown-sub.svg'} alt="Subskrypcje" /> Subskrypcje</li></Link>
                        <Link to="/app?=logout"><li className="logout" onClick={handleLogout}><img src={'./icons/dropdown-logout.svg'} alt="Wyloguj się" /> Wyloguj się</li></Link>
                    </>
                )}
            </ul>
        </nav>
    }

    return (
        <nav className="MobileTopNavigationBar">
            <div className="navbar-logo">
                <Link to={user ? '/dashboard' : '/'}>
                    <img src="/logo-small.svg" alt="Shape Factor Logo" className="logo" />
                </Link>
            </div>


            <Link to="/basket" className={user ? "cart-link" : "cart-link"}>
                <ShoppingBag size={24} />
                {itemsInBasket > 0 && (
                    <span className="bag-badge text-xs semibold">{itemsInBasket}</span>
                )}
            </Link>

            {click ? Close : Hamburger}

            {/* {click && <div className="navbar-links">
                <p><Link to="/profile">
                    <SecondaryButton text={"Profil"} />
                </Link></p>
            </div>
            } */}

            {click && <NavLinks isClicked={true} closeMenu={closeMenu} />}
        </nav>
    );
};

export default MobileTopNavigationBar