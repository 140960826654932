import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, connectAuthEmulator} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_DATABASE_URL,
  storageBucket: "shape-factor.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROJECT_ID,
  measurementId: process.env.REACT_APP_F_MESUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const firestoreDB = getFirestore(app);

export const auth = getAuth(app);
export const db = firestoreDB;