import React from "react";
import '../../styles/components/buttons/PrimaryButton.css';

const PrimaryButton = ({ text, onClick, type = 'primary', disabled = false}) => {
    return(
        <button
            className="primary-button"
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            <p className="mt-1 mb-1 white-text text-sm">{text}</p>
        </button>
    )
}

export default PrimaryButton;