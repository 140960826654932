import React, { useState, useEffect } from 'react';
import '../../../styles/Screens/OrdersContent/OrdersContent.css'
import { ArrowRight, ArrowLeft } from 'react-feather';
import { fetchOrders, fetchOrderById } from '../../../services/order/OrderService';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import OrderView from '../../public/OrderView';

function formatDate(dateStr) {
    const date = new Date(dateStr);

    if (isNaN(date)) {
        return dateStr;
    }

    return format(date, 'dd MMMM yyyy', { locale: pl });
}

const OrdersContent = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        const getOrders = async () => {
            try {
                const data = await fetchOrders();
                setOrders(data.data || []);
                setLoading(false);
            } catch {
                setLoading(false);
            }
        };
        getOrders();
    }, []);

    const getOrderDetails = async (e, id) => {
        e.preventDefault();

        try {
            const data = await fetchOrderById(id);
            setSelectedOrder(id);
        } catch {

        }
    }

    const handleBackClick = () => {
        setSelectedOrder(null);
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (selectedOrder) {
        return (
            <div className="order-view-container">
                <div className="back-button" onClick={handleBackClick} style={{ cursor: 'pointer', marginBottom: '20px' }}>
                    <ArrowLeft size={24} color='var(--color-purple)' /> 
                </div>
                <OrderView orderId={selectedOrder} />
            </div>
        );
    }

    return (
        <div className="orders-content">
            <h2>Zamówienia</h2>
            <table className="orders-table">
                <thead className="text-xs medium">
                    <tr>
                        <th>Produkty</th>
                        <th>Numer zamówienia</th>
                        <th>Data zamówienia</th>
                        <th>Koszt zamówienia</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className='text-s semibold'>
                    {orders.map(order => (
                        <tr key={order.id}>
                            <td className='product'>
                                <img src='./icons/product-1.svg' alt="Produkt" className="product-image" />
                            </td>
                            <td className="order-id">{order.id}</td>
                            <td>{formatDate(order.created_time)}</td>
                            <td>{order.total_amount.toFixed(2)} zł</td>
                            <td className='details-array'>
                                <ArrowRight size={20} color='var(--color-purple)' onClick={(e) => getOrderDetails(e, order.id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrdersContent;