import axios from 'axios';
import { auth } from './authorization/firebase.config';

const API_BASE_URL = 'https://api.shapefactor.pl/';

const httpService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const getToken = async () => {
  const user = auth.currentUser;
  if (user) {
    return await user.getIdToken();
  }
  return null;
};

const sendRequest = async (method, path, body = null) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    const response = await httpService.request({
      method,
      url: path,
      data: body,
      ...config
    });

    return response.data;
  } catch (error) {
    console.error('Error sending request:', error);
    throw error;
  }
};

export const postRequest = async (path, body) => {
  return await sendRequest('POST', path, body);
};

export const getRequest = async (path) => {
  return await sendRequest('GET', path);
};

export const putRequest = async (path, body) => {
  return await sendRequest('PUT', path, body);
};

export const deleteRequest = async (path) => {
  return await sendRequest('DELETE', path);
};