import { sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { auth } from './firebase.config';


export const resetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        console.log('Link do resetowania hasła wysłany!');
    } catch (error) {
        console.error('Błąd resetowania hasła:', error.message);
        throw error;
    }
};

export const confirmPasswordResetAction = async (oobCode, newPassword) => {
    try {
      await verifyPasswordResetCode(auth, oobCode);
      await confirmPasswordReset(auth, oobCode, newPassword);
      console.log('Hasło zostało pomyślnie zresetowane!');
    } catch (error) {
      console.error('Błąd potwierdzania resetu hasła:', error.message);
      throw error;
    }
  };